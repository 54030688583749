import "../sass/components/Contact.sass";
import React from "react";
import logo from "../assets/images/AIC LOGO BLACK.svg";
import { Facebook, LinkedIn, Twitter, YouTube } from "@material-ui/icons";
import { useStateValue } from "../utilities/StateProvider";

const Contact = () => {
  const [, dispatch] = useStateValue();

  return (
    <div id="contact">
      <div className="container">
        <div className="wrapper">
          <h2>Contact us</h2>
          <div className="row">
            <div className="col">
              <h5>Phone</h5>
              <a className="call" href="tel:+1-774-369-0979">
                +1-774-369-0979
              </a>{" "}
              <h5>Address</h5>
              <p>
                225 Cedar Hill St <br />
                Suite 200 <br />
                Marlborough, MA 01752 <br />
                United States
              </p>
              <button onClick={() => dispatch({ type: "OPEN_DIRECTIONS" })}>
                Driving directions and map
              </button>
              <h5>Email</h5>
              <a href="mailto:contact@ambitioninnovationcreativity.com">
                contact@ambitioninnovationcreativity.com
              </a>
            </div>
            <div className="col">
              <img src={logo} alt="AIC logo" />
              <div className="social">
                <a href="https://www.facebook.com/AmbitionInnovationCreativity">
                  <Facebook />
                </a>
                <a href="https://www.linkedin.com/company/ambitioninnovationcreativity">
                  <LinkedIn />
                </a>
                <a href="https://twitter.com/Ambition_In_Cr">
                  <Twitter />
                </a>
                <a href="https://www.youtube.com/channel/UCfsEmPCeuuV27smLOz1aTbw">
                  <YouTube />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
