import "../sass/components/Services.sass";
import React from "react";

const Services = () => {
  return (
    <div id="services">
      <div className="container">
        <div className="wrapper">
          <h2>Our services</h2>
          <div className="cards-container">
            <ul>
              <li>Technology Consulting</li>
              <li>Application Development and Integration</li>
              <li>Application Maintenance and Support</li>
              <li>Custom Software Developement</li>
              <li>API Development and Integration</li>
              <li>Kiosks Software & Hardware Solutions</li>
              <li>IoT (Internet of Things) Software & Hardware Solutions</li>
              <li>Cloud Computing Solutions</li>
              <li>E-commece Solutions</li>
              <li>STEM: Coding and Robotics Educational Services</li>
              <li>Technology Training Programs</li>
              <li>Personal & Business Development Coaching</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
