import "./sass/App.sass";

import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Toaster } from "react-hot-toast";

import Home from "./pages/Home";
import Recorder from "./pages/Recorder";
import InteractiveVideo from "./pages/InteractiveVideo";
import Messages from "./pages/Messages";

import Form from "./components/Form";
import Directions from "./components/Directions";
import Uploaded from "./components/Uploaded";

import { useStateValue } from "./utilities/StateProvider";
import ScreenRecorder from "./pages/ScreenRecorder";

const App = () => {
  const [{ directions, contact }, dispatch] = useStateValue();

  useEffect(() => {
    // Mobile detection
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    ) && dispatch({ type: "SET_MOBILE" });
  }, [dispatch]);

  useEffect(() => {
    contact || directions
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
  }, [contact, directions]);

  return (
    <Router>
      <Toaster
        toastOptions={{
          duration: 5000,
          style: { borderRadius: "6rem", fontWeight: "bold", padding: "1rem" },
        }}
      />
      <Switch>
        <Route path="/" exact component={Home} />
        {/* <Route path="/interactive-video" exact component={InteractiveVideo} />
        <Route path="/recorder/:mediaType" component={Recorder} />
        <Route path="/messages" exact component={Messages} />
        <Route path="/uploaded" exact component={Uploaded} />
        <Route path="/screen" exact component={ScreenRecorder} /> */}
      </Switch>
      {contact && <Form />}
      {directions && <Directions />}
    </Router>
  );
};

export default App;
