import React, { useEffect } from "react";

import About from "../components/About";
import Contact from "../components/Contact";
import Goto from "../components/Goto";
import Landing from "../components/Landing";
import Loader from "../components/Loader";
import Navbar from "../components/Navbar";
import Services from "../components/Services";

import { useStateValue } from "../utilities/StateProvider";

const Home = () => {
  const [{ loading }, dispatch] = useStateValue();

  useEffect(() => {
    document.body.style.overflow = "auto";
  }, []);

  useEffect(() => {
    dispatch({ type: "STOP_LOADING" });

    return () => dispatch({ type: "START_LOADING" });
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Navbar bg />
      <Landing />
      {/* <About /> */}
      <Services />
      <Contact />
      <Goto />
    </div>
  );
};

export default Home;
