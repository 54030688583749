import "../sass/components/Directions.sass";
import React from "react";
import { useStateValue } from "../utilities/StateProvider";
import { Close } from "@material-ui/icons";
import map from "../assets/images/map.png";

const Directions = () => {
  const [, dispatch] = useStateValue();

  return (
    <div id="directions">
      <div
        className="overlay"
        onClick={() => dispatch({ type: "CLOSE_DIRECTIONS" })}
      ></div>
      <button
        className="close-btn"
        onClick={() => dispatch({ type: "CLOSE_DIRECTIONS" })}
      >
        <Close />
      </button>
      <div className="container">
        <h2>Map and directions</h2>
        <img src={map} alt="map" />
        <div className="info">
          <h5>Address</h5>
          <p>
            225 Cedar Hill St <br />
            Suite 200 <br />
            Marlborough, MA 01752 <br />
            United States
          </p>
          <a
            onClick={() => dispatch({ type: "CLOSE_DIRECTIONS" })}
            rel="noreferrer"
            target="_blank"
            href="https://www.google.com/maps/dir//Ambition+Innovation+Creativity/data=!4m8!4m7!1m0!1m5!1m1!1s0x89e3f583fdcbc2a7:0xe05d3223e15fcce8!2m2!1d-71.5858804!2d42.3203439"
          >
            Get map directions
          </a>
        </div>
      </div>
    </div>
  );
};

export default Directions;
