import "../sass/components/Landing.sass";
import React from "react";
import { Today } from "@material-ui/icons";
import { openPopupWidget } from "react-calendly";

const Landing = () => {
  return (
    <div id="landing">
      <div className="container">
        <div className="wrapper">
          <div className="text">
            <h1>Ambition Innovation Creativity</h1>
            <h3>Technology Consulting & Beyond</h3>
          </div>
          <button
            className="calendar-btn"
            onClick={() =>
              openPopupWidget({ url: "https://calendly.com/malekoo" })
            }
          >
            Schedule a Meeting <Today />{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Landing;
