import "../sass/components/Goto.sass";
import React from "react";
import {
  KeyboardArrowUp,
  Mail,
  Phone,
  Today,
  WhatsApp,
} from "@material-ui/icons";
import { openPopupWidget } from "react-calendly";
import { useStateValue } from "../utilities/StateProvider";

const Goto = () => {
  const [, dispatch] = useStateValue();

  return (
    <div id="bottom-bar">
      <a href="#" className="go-to-btn">
        <KeyboardArrowUp />
      </a>
      <button
        onClick={() => dispatch({ type: "OPEN_CONTACT" })}
        href="mailto:contact@ambitioninnovationcreativity.com"
        className="go-to-btn"
      >
        <Mail />
        <p>Contact</p>
      </button>
      <button
        className="go-to-btn"
        onClick={() => openPopupWidget({ url: "https://calendly.com/malekoo" })}
      >
        <Today />
        <p>Book</p>
      </button>
      <a href="tel:+1-774-369-0979" className="go-to-btn">
        <Phone />
        <p>Call</p>
      </a>
      <a
        href="https://wa.me/message/U3F4BECXJKPOJ1"
        target="_blank"
        rel="noreferrer"
        className="go-to-btn"
      >
        <WhatsApp />
        <p>WhatsApp</p>
      </a>
    </div>
  );
};

export default Goto;
