import "../sass/components/Loader.sass";
import React from "react";

const Loader = ({ text, upload }) => {
  return (
    <div className={`showbox ${upload && "upload"}`}>
      <div className="loader">
        <svg className="circular" viewBox="25 25 50 50">
          <circle
            className="path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="3"
            strokeMiterlimit="10"
          />
        </svg>
      </div>
      {text && <p>{text}</p>}
    </div>
  );
};

export default Loader;
