import "../sass/components/Form.sass";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { Close } from "@material-ui/icons";
import { useStateValue } from "../utilities/StateProvider";

const Form = () => {
  const [, dispatch] = useStateValue();

  const [data, setData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://k2s0azxoqg.execute-api.us-east-1.amazonaws.com/dev",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );

      setData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });

      response.status === 200
        ? toast.success("Email sent!")
        : toast.error("Something went wrong!");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  const changeValue = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div id="contact-form">
      <div
        className="overlay"
        onClick={() => dispatch({ type: "CLOSE_CONTACT" })}
      ></div>
      <button
        className="close-btn"
        onClick={() => dispatch({ type: "CLOSE_CONTACT" })}
      >
        <Close />
      </button>
      <div className="container">
        <h2>Contact us</h2>
        <form onSubmit={submitHandler}>
          <label htmlFor="full-name">Full Name</label>
          <input
            id="full-name"
            name="name"
            type="text"
            value={data.name}
            onChange={changeValue}
          />
          <label htmlFor="email">E-mail *</label>
          <input
            id="email"
            type="email"
            name="email"
            value={data.email}
            onChange={changeValue}
            required
          />
          <label htmlFor="subject">Subject</label>
          <input
            id="subject"
            type="text"
            name="subject"
            value={data.subject}
            onChange={changeValue}
          />

          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            cols="10"
            rows="10"
            value={data.message}
            onChange={changeValue}
          ></textarea>
          <button className="btn-link" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Form;
