import "../sass/components/Navbar.sass";
import React from "react";
import logo from "../assets/images/AIC LOGO NAVBAR.svg";
import { useState } from "react";
import { useEffect } from "react";
import { debounce } from "../utilities/helpers";
import { Mail } from "@material-ui/icons";
import { useStateValue } from "../utilities/StateProvider";
import { Link } from "react-router-dom";

const Navbar = ({ bg }) => {
  const [{ mobile }, dispatch] = useStateValue();

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [clicked, setClicked] = useState(false);

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;

    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 30) ||
        currentScrollPos < 10
    );

    setPrevScrollPos(currentScrollPos);
  }, 100);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  useEffect(() => {
    if (visible === false) {
      setClicked(false);
    }
  }, [visible]);

  useEffect(() => {
    return () => {
      setPrevScrollPos(0);
      setVisible(true);
      setClicked(false);
    };
  }, []);

  return (
    <nav
      className={mobile ? "mobile" : ""}
      style={{
        backgroundColor: bg || clicked ? "#FFFFFF" : "#00000000",
        top: !visible && bg ? "-15rem" : "0",
      }}
    >
      <div className="container">
        <div className="navbar">
          <Link to="/" onClick={() => setClicked(false)}>
            <img className="logo" src={logo} alt="AIC Logo" />
          </Link>
          <button
            onClick={() => setClicked(!clicked)}
            className={`btn-menu ${clicked && visible ? "clicked" : ""}`}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div
            className={`nav-container ${clicked && visible ? "open" : "close"}`}
          >
            <ul>
              <li>
                <Link to="/" onClick={() => setClicked(false)}>
                  Home
                </Link>
              </li>
              {/* <li>
                <Link to="#about" onClick={() => setClicked(false)}>
                  About us
                </Link>
              </li> */}
              <li>
                <Link to="#services" onClick={() => setClicked(false)}>
                  Our Services
                </Link>
              </li>
              <li className="contact">
                <button
                  onClick={() => {
                    setClicked(false);
                    dispatch({ type: "OPEN_CONTACT" });
                  }}
                >
                  Contact us <Mail />{" "}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
