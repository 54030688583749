import axios from "axios";

// API URL
const API = axios.create({
  baseURL: "https://9t3jyihrmd.execute-api.us-east-1.amazonaws.com/dev",
});

// Get res-signed URL function
export const getUrl = (isVideo) =>
  API.get("/url", { params: { type: isVideo ? "videos" : "audios" } });

// Save file in S3 function
export const saveFile = (url, file, isVideo) =>
  axios.put(url, file, {
    headers: {
      "Content-Type": isVideo ? "video/mp4" : "audio/mpeg",
    },
  });

// Save user data function
export const saveUser = (user) =>
  API.put("/user", user, {
    headers: {
      "Content-Type": "application/json",
    },
  });
// Create a discussion function
export const createDis = (data) =>
  API.put("/discussion", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
// Save media in DB function
export const saveMedia = (data) =>
  API.put("/media", data, {
    headers: { "Content-Type": "application/json" },
  });
