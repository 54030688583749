export const initialState = {
  contact: false,
  directions: false,
  videoStart: false,
  alert: null,
  show: false,
  mobile: false,
  loading: false,
  file: null,
  uploaded: false,
  showForm: false,
  message: "",
};

const reducer = (state, action) => {
  console.log(action);
  switch (action.type) {
    case "OPEN_CONTACT":
      return {
        ...state,
        contact: true,
      };

    case "CLOSE_CONTACT":
      return {
        ...state,
        contact: false,
      };

    case "OPEN_DIRECTIONS":
      return {
        ...state,
        directions: true,
      };

    case "CLOSE_DIRECTIONS":
      return {
        ...state,
        directions: false,
      };

    case "SET_ALERT":
      return {
        ...state,
        alert: action.payload,
      };

    case "SET_SHOW":
      return {
        ...state,
        show: true,
      };

    case "CLEAR_SHOW":
      return {
        ...state,
        show: false,
      };

    case "START_VIDEO":
      return {
        ...state,
        videoStart: true,
      };

    case "STOP_VIDEO":
      return {
        ...state,
        videoStart: false,
        show: false,
      };

    case "SET_MOBILE":
      return {
        ...state,
        mobile: true,
      };

    case "START_LOADING":
      return {
        ...state,
        loading: true,
      };

    case "STOP_LOADING":
      return {
        ...state,
        loading: false,
      };

    case "SET_FILE":
      return {
        ...state,
        file: action.payload,
      };

    case "CLEAR_FILE":
      return {
        ...state,
        file: null,
      };

    case "SET_UPLOADED":
      return {
        ...state,
        uploaded: true,
      };

    case "CLEAR_UPLOADED":
      return {
        ...state,
        uploaded: false,
      };

    case "SET_MESSAGE":
      return {
        ...state,
        message: action.payload,
      };

    case "CLEAR_MESSAGE":
      return {
        ...state,
        message: "",
      };

    case "SHOW_FORM":
      return {
        ...state,
        showForm: true,
      };

    case "HIDE_FORM":
      return {
        ...state,
        showForm: false,
      };

    default:
      return state;
  }
};

export default reducer;
